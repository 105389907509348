export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#B0B8BA",
  gray: "#A0A4A6",
  babyBlue: "#50CAF9",
  blue: "#5368f1",
}

export const GRADIENT = `linear-gradient(to top right, ${COLORS.babyBlue}, ${COLORS.blue})`

export const BORDER_RADIUS = "10px"

export const APP_STORE_LINK =
  "https://itunes.apple.com/jp/app/id1494996904?mt=8"

export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.natade_coco.pocket"

export const DEMO_VIDEO_LINK = "https://natade-coco.gitlab.io/assets/demo.mp4"

export const DEMO_VIDEO_LINK_ANDROID =
  "https://natade-coco.gitlab.io/assets/demo_android.mp4"

export const FEATURE_LINK = "https://natade-coco.gitlab.io/assets/concept.png"
