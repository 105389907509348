import React, { useState } from "react"
import Cookie from "universal-cookie"
import Styles from "./consent.module.scss"

const cookieName = "gatsby-gdpr-google-analytics"
const cookies = new Cookie()

const Consent = () => {
  const [dismiss, setDismiss] = useState(cookies.get(cookieName) !== undefined)

  const handleClick = (e) => {
    cookies.set(cookieName, e.target.name === "agree" ? "true" : "false", {path: "/", maxAge: 31536000})
    setDismiss(true)
  }

  return (
    !dismiss &&
    <div id="consent" className={Styles.fixedConsent}>
      <div className={`is-size-7 ${Styles.message}`}>
        <p>このサイトでは、サイトの信頼性や利便性向上を目的とした、利用者の利用動向を調査するアクセス解析ツールを利用するために cookie を使用します。
        <a className={Styles.anchor} href="https://info.natade-coco.com/policies/privacy/" target="_blank" rel="noopener noreferrer">詳しくはこちら</a></p>
      </div>
      <div className={`${Styles.buttons}`}>
        <div className={`buttons ${Styles.pushRight}`}>
          <button className={`button is-small is-outlined has-text-weight-bold ${Styles.button}`} name="reject" onClick={handleClick}>同意しない</button>
          <button className={`button is-small is-info has-text-weight-bold ${Styles.button}`} name="agree" onClick={handleClick}>同意する</button>
        </div>
      </div>
    </div>
  )
}

export default Consent
